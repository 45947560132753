function importAll(r) {
  return r.keys().map(r);
}

let images22 = []
let images21 = []
let images20 = []
let images19 = []
let images18 = []

const image_arr_2022 = importAll(require.context('./images/2022-pics', false, /\.(png|jpe?g|svg)$/));
const image_arr_2021 = importAll(require.context('./images/2021-pics', false, /\.(png|jpe?g|svg)$/));
const image_arr_2020 = importAll(require.context('./images/2020-pics', false, /\.(png|jpe?g|svg)$/));
const image_arr_2019 = importAll(require.context('./images/2019-pics', false, /\.(png|jpe?g|svg)$/));
const image_arr_2018 = importAll(require.context('./images/carousel-2018', false, /\.(png|jpe?g|svg)$/));

image_arr_2022.forEach(function(item, index) { 
    images22.push(
      {
        original:item["default"],
        caption:"HJD 2022"
      });
});

image_arr_2021.forEach(function(item, index) { 
    images21.push(
      {
        original:item["default"],
        caption:"HJD 2021"
      });
});

image_arr_2020.forEach(function(item, index) { 
  images20.push(
    {
      original:item["default"],
      caption:"HJD 2020"
    });
});

image_arr_2019.forEach(function(item, index) { 
  images19.push(
    {
      original:item["default"],
      caption:"HJD 2019"
    });
});

image_arr_2018.forEach(function(item, index) { 
  images18.push(
    {
      original:item["default"],
      caption:"HJD 2018"
    });
});

export default {images22, images21, images20, images19, images18};