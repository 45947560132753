import React from 'react';
import venmo from '../images/venmo.jpg';
import cashapp from '../images/cashapp.jpg';
import paypal from '../images/paypal.png';

const Donate = () => (
  <>
    <h1> DONATE </h1>
    {/* <hr /> */}
    <div className="main-text">
      <p> You can donate directly through the Holiday Joy Drive Venmo (@holidayjoydrive2023), Cash App ($HolidayJoyDrive), or PayPal (<a href="https://www.paypal.com/paypalme/HolidayJoyDrive" target="_blank" rel="noopener noreferrer">PayPal.Me/HolidayJoyDrive</a>) accounts!<br></br><br></br>
        This method avoids a transaction fee and every penny will go to toy purchases for the Boys and Girls Clubs!  Scan the QR codes below for a direct link to either platform.
      </p>
      <div className="qrcodes">
      <img className="rcorners qr" src={ venmo } alt="Venmo QR Code" display="block" marginLeft="auto" marginRight="auto" float="center"/>
      <img className="rcorners qr cashapp" src={ cashapp } alt="Cash App QR Code" display="block" marginLeft="auto" marginRight="auto" float="center"/>
      <img className="rcorners qr" src={ paypal } alt="Paypal QR Code" display="block" marginLeft="auto" marginRight="auto" float="center"/>
      </div>
    </div>
  </>
);

export default Donate;