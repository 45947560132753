import './App.css';
import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Updates from './pages/Updates';
import Donate from './pages/Donate';
import NotFound404 from './pages/NotFound404';
import Navbar from './Navbar';
import Footer from './Footer';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <div id="header">
          </div>
          <div className="nav">
            <Navbar />
          </div>

          <div id="page-body">
            <Switch>
              <Route path="/" component={Home} exact />
              <Route path="/about" component={About}  />
              <Route path="/contact" component={Contact}  />
              <Route path="/donate" component={Donate}  />
              <Route path="/updates" component={Updates}  />
              <Route component={NotFound404} />
            </Switch>
          </div>
          <hr />
          <div className="footer">
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    )
  }
}

export default App;
