import React from 'react';
import presents from '../images/presents.jpg';

const Home = () => (
  <>
    <h1> HOLIDAY JOY DRIVE </h1>
    {/* <hr /> */}
    <div class="main-text">
      <img id="main-image" className="rcorners" src={ presents } alt="Holiday Joy Drive Presents 2022" />
      <h3>The holidays are a magical time for kids.</h3>
      <p> And together, we can make it even more special for children in the Milwaukee and Madison areas.<br></br><br></br>
        The Holiday Joy Drive gives Milwaukee- and Madison-area residents the opportunity to help local kids have a special holiday season.<br></br><br></br>
        We've taken incredible strides in our first five years, growing from 131 toys donated in 2018 to nearly 1,000 toys per year in the last three holiday seasons.<br></br><br></br>
        In 2023, we aim to set new records while continuing to grow roots in the Milwaukee and Madison communities! 
      </p>
    </div>
    <hr />
    <div class='table-wrapper'>
      <div class='row'>
        <div class='column community'>
            <h3>Our Communities</h3>
            <p>Donate to your choice of the Boys and Girls Clubs of Greater Milwaukee or Dane County.</p>
        </div>
        <div class='column mission'>
          <h3>Our Mission</h3>
          <p>To spread the joy of the holiday season by giving Milwaukee- and Madison-area kids something special to open each year.</p>
        </div>
        <div class='column impact'>
          <h3>Our Impact</h3>
          <p>Every dollar, every toy and every donation adds up. Together, we can make a real difference for kids in our communities.</p>
        </div>
      </div>
    </div>
  </>
);

export default Home;