import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => (
  <nav>
    <ul>
      <li>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/contact">Contact</Link>
        <Link to="/updates">Updates</Link>
        <Link to="/donate">Donate</Link>
      </li>
    </ul>
  </nav>

)

export default Navbar;