import ImageGallery from 'react-image-gallery';
import React from 'react'
import images from '../Carousel'
import '../index.css';


class Updates extends React.Component {
  render() {
    return [
        <p class="carousel-header-top">2022</p>,
        <ImageGallery key={1} items={images.images22} />,
        <p class="carousel-header">2021</p>,
        <ImageGallery key={1} items={images.images21} />,
        <p class="carousel-header">2020</p>,
        <ImageGallery key={2} items={images.images20} />,
        <p class="carousel-header">2019</p>,
        <ImageGallery key={3} items={images.images19} />,
        <p class="carousel-header">2018</p>,
        <ImageGallery key={4} items={images.images18} />
    ]
  }
}

export default Updates;