import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <>
    <a
      href="http://www.facebook.com/sharer.php?u=https://www.holidayjoydrive.org/&t=Home%20" 
      className="facebook social"
      target="_blank"
      rel="noreferrer noopener"
    >
      <FontAwesomeIcon icon={faFacebook} size="2x" />
    </a>
    <a href="http://twitter.com/share?text=Home%20&url=https%3A%2F%2Fwww.holidayjoydrive.org%2F" className="twitter social"
      target="_blank"
      rel="noreferrer noopener"
    >
      <FontAwesomeIcon icon={faTwitter} size="2x" />
    </a>
    <a
      href="https://www.instagram.com/"
      className="instagram social"
      target="_blank"
      rel="noreferrer noopener"
    >
      <FontAwesomeIcon icon={faInstagram} size="2x" />
    </a>
    </>
  )
};

export default Footer;